import { ApolloClient } from '@apollo/client';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { AsyncStorageWrapper, CachePersistor, MMKVWrapper } from 'apollo3-cache-persist';
import { ComponentProps, forwardRef, useEffect, useState } from 'react';
import { Platform } from 'react-native';

import { AppContainer } from '@oui/app-core/src/components/AppContainer';
import { FLAGS, IS_PRODUCTION } from '@oui/app-core/src/constants';
import { createApolloCache, createApolloClient } from '@oui/app-core/src/lib/apolloClient';
import { getMmkv, initMmkv } from '@oui/app-core/src/lib/mmkv';
import { getConfigString, initLastConfig } from '@oui/app-core/src/lib/remoteConfig';
import Sentry from '@oui/app-core/src/sentry';
import { CORE_DEEPLINK_CONFIG, DeeplinkConfigShape } from '@oui/app-core/src/types/navigation';
import { StaticTabParamList } from '@oui/app-static/src/types/navigation';

import FullLogo from '@src/assets/Full_logo.svg';
import { RootNavigator } from '@src/RootNavigator';
import { HomeTabParamList, RootStackParamList } from '@src/types/navigation';

const getMessages: ComponentProps<typeof AppContainer>['getMessages'] = ({ lang }) => {
  switch (lang) {
    case 'en': {
      return require('@src/messages/compiled/en.json');
    }
    default:
      return {};
  }
};

const homeConfig = {
  Home: 'home',
  Learn: 'learn',
  MyPlan: 'myplan',
  Account: 'account',
} as const satisfies DeeplinkConfigShape<keyof HomeTabParamList>;
const homeStaticConfig = {
  Learn: 'static/learn',
  Practice: 'static/practice',
  Aid: 'static/aid',
  Profile: 'static/account',
} as const satisfies DeeplinkConfigShape<keyof StaticTabParamList>;
export const DEEPLINK_CONFIG = {
  home: { screens: homeConfig },
  homeStatic: { screens: homeStaticConfig },
  Welcome: 'welcome',
  Connected: 'auth/support',
  Confidentiality: 'Confidentiality',
  CreateTestUser: 'CreateTestUser',
  StaticReview: 'StaticReview',
  StaticSession: 'StaticSession',
  ...CORE_DEEPLINK_CONFIG,
} as const satisfies DeeplinkConfigShape<keyof RootStackParamList>;

type Props = {};
export default forwardRef<AppContainer, Props>(function App({}: Props, ref) {
  const [apollo, setApollo] = useState<ApolloClient<unknown>>();

  useEffect(() => {
    // This will only run in development when fast-refresh kicks in
    let cleanupRef = { current: () => {} };

    async function initApollo() {
      const cache = createApolloCache();

      // TODO move to mountApp?
      await initMmkv();

      try {
        const options =
          Platform.OS === 'web'
            ? IS_PRODUCTION
              ? undefined
              : {
                  cache,
                  trigger: 'write' as const,
                  storage: new AsyncStorageWrapper(AsyncStorage),
                }
            : {
                cache,
                trigger: 'background' as const,
                storage: new MMKVWrapper(getMmkv('apollo')),
              };

        if (options) {
          const persistor = new CachePersistor(options);
          await persistor.restore();
          cleanupRef.current = () => persistor.remove();
        }
      } catch (e) {
        Sentry.captureException(e);
      }

      await initLastConfig();
      const client = createApolloClient(getConfigString('apiUrl'), {
        subscriptionUri: getConfigString('subscriptionUri'),
        cache,
        connectToDevTools: true,
      });
      setApollo(client);
      const start = Date.now();
      const removedKeys = cache.gc();
      const duration = Date.now() - start;
      Sentry.addBreadcrumb({
        category: 'apollo',
        message: 'cache.gc',
        data: { removedKeys, duration },
      });
    }
    initApollo();

    return () => cleanupRef.current();
  }, []);

  return apollo ? (
    <AppContainer
      Logo={FullLogo}
      flags={FLAGS}
      app={() => <RootNavigator />}
      apollo={apollo}
      ref={ref}
      initialPath={({
        ouiUser,
      }): Parameters<GeneratedClientRoutes.GetDeeplinkPath['avivaSupport']>[0] => {
        if (!ouiUser) return '/welcome';
        if (ouiUser.user?.__typename === 'Patient') {
          if (ouiUser.user.productStatic) {
            return '/static/learn';
          }
        }
        return '/home';
      }}
      deeplinkConfig={{
        screens: DEEPLINK_CONFIG,
      }}
      getMessages={getMessages}
    />
  ) : null;
});
